import { t } from "@lingui/macro";
import { GetStaticProps, NextPage } from "next";
import { NextSeo } from "next-seo";
import { useRouter } from "next/compat/router";
import { ParsedUrlQuery } from "querystring";
import { useCallback } from "react";
import styled from "styled-components";
import {
  fetchDestinationPages,
  useDestinationPages,
} from "~/applications/OfferSearch/Api/useDestinationPages";
import {
  DestinationPage,
  destinationToMenuEntry,
} from "~/applications/OfferSearch/Domain/DestinationPage";
import BookDrawerModal from "~/applications/OfferSearch/Ui/BookDrawer/BookDrawerModal";
import { BrandIdentity } from "~/components/BrandIdentity/BrandIdentity";
import { fetchBrandIdentity } from "~/components/BrandIdentity/useBrandIdentity";
import Footer from "~/components/Footer";
import CircularCarousel from "~/components/PictureGallery/CircularCarousel";
import { PublicPageSeo } from "~/components/Seo";
import { ResourceVisibility } from "~/core/ResourceVisibility";
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  processUserLocale,
} from "~/core/locale";
import { useModalController } from "~/core/modal";
import { homepageRoute, offersRoute } from "~/core/routes";
import { Stack } from "~/guidelines/Flex";
import { serverSideTranslations } from "~/translations/serverSideTranslations";
import { ServerSideGeneratedI18nNamespace } from "~/translations/types";
import screen1 from "./new-home/_assets/digital-experience/screen-1.png";
import screen2 from "./new-home/_assets/digital-experience/screen-2.png";
import screen3 from "./new-home/_assets/digital-experience/screen-3.png";
import screen4 from "./new-home/_assets/digital-experience/screen-4.png";
import screen5 from "./new-home/_assets/digital-experience/screen-5.png";
import screen6 from "./new-home/_assets/digital-experience/screen-6.png";
import B2B from "./new-home/_components/B2B";
import DestinationsCarousel from "./new-home/_components/DestinationsCarousel";
import Faq from "./new-home/_components/Faq";
import Hero from "./new-home/_components/Hero";
import Privatization from "./new-home/_components/Privatization";
import Promises from "./new-home/_components/Promises";
import SimpleHeader from "./new-home/_components/SimpleHeader";
import { Testimonials } from "./new-home/_components/Testimonials";
import OpenGraphImageHome from "~/guidelines/Logo/EncoreMieuxOG.png";

interface HomePageProps extends ServerSideGeneratedI18nNamespace {
  locale: AVAILABLE_LOCALES;
  prefetchedDestinationPages: Array<DestinationPage>;
  prefetchedBrandIdentity: BrandIdentity;
}

interface HomePageParams extends ParsedUrlQuery {
  destinationSlug: string;
}

export const getStaticProps: GetStaticProps<
  HomePageProps,
  HomePageParams
> = async (context) => {
  let locale = context.locale as "default" | AVAILABLE_LOCALES | undefined;

  if (locale === "default") {
    locale = undefined;
  }

  if (locale === undefined) {
    locale = processUserLocale({
      acceptLanguage: locale ?? "",
      defaultLocale: DEFAULT_LOCALE,
    });
  }

  // We fetch only public destination for page data
  const prefetchedDestinationPages = await fetchDestinationPages({
    locale,
    visibilities: [ResourceVisibility.PUBLIC],
  });

  const prefetchedBrandIdentity = await fetchBrandIdentity();

  return {
    props: {
      locale,
      prefetchedDestinationPages,
      prefetchedBrandIdentity,
      ...(await serverSideTranslations(locale)),
    },
  };
};

const PUBLIC_URL = process.env.PUBLIC_URL;

const Layout = styled(Stack)`
  background-color: ${({ theme }) => theme.color.background.lightSecond};
  align-items: center;
`;

const HomePage: NextPage<HomePageProps> = (params: HomePageProps) => {
  const router = useRouter();
  const { destinationPages } = useDestinationPages(
    { locale: params.locale },
    params.prefetchedDestinationPages,
  );
  const bookDrawerModalController = useModalController(BookDrawerModal);

  const onSearchStay = useCallback(() => {
    if (!destinationPages || destinationPages.length === 0) {
      return;
    }

    bookDrawerModalController.show({
      initialDestinationSlug: destinationPages[0].seoSlug,
      destinations:
        destinationPages?.map(destinationToMenuEntry) ??
        params.prefetchedDestinationPages.map(destinationToMenuEntry),
      onSearchRoomOffer: ({
        destination,
        stayRequest,
        cancellationPolicyId,
      }) => {
        router?.push(
          offersRoute(destination.seoSlug, stayRequest, cancellationPolicyId),
        );
      },
      offerMaxCapacity: undefined,
      onNewSearch: undefined,
    });
  }, [
    destinationPages,
    bookDrawerModalController,
    params.prefetchedDestinationPages,
    router,
  ]);

  const onLocalChange = (newLocale: AVAILABLE_LOCALES) => {
    router
      ?.push(
        {
          pathname: router.pathname,
          query: router.query,
        },
        undefined,
        {
          locale: newLocale,
        },
      )
      .then(() => {
        window.scrollTo(0, 0);
        router.reload();
      });
  };
  if (!router) {
    return null;
  }

  const seoTitle = t`Encore Mieux | Official Site`;

  return (
    <Layout>
      <PublicPageSeo />
      <NextSeo
        title={seoTitle}
        description={t`Discover Encore Mieux, the benchmark for autonomous hospitality in France. Enjoy a premium, digitalized stay rooted in local heritage for a timeless experience.`}
        openGraph={{
          type: "siteweb",
          title: seoTitle,
          description: t`Experience autonomous hospitality with premium comfort, digital convenience, and a deep connection to local heritage for a timeless stay.`,
          url: PUBLIC_URL + router.asPath,
          images: [
            {
              url: OpenGraphImageHome.src,
              alt: seoTitle,
              width: OpenGraphImageHome.width,
              height: OpenGraphImageHome.height,
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
      />
      <SimpleHeader
        logoHref={homepageRoute()}
        currentLocale={params.locale}
        availableLocales={Object.values(AVAILABLE_LOCALES)}
        onLocaleChange={onLocalChange}
      />
      <Hero destinations={destinationPages ?? []} onSearch={onSearchStay} />
      <CircularCarousel
        screens={[screen1, screen2, screen3, screen4, screen5, screen6]}
      />
      <Promises />
      <DestinationsCarousel destinations={destinationPages ?? []} />
      <Testimonials destinations={destinationPages ?? []} />
      <Privatization />
      <Faq />
      <B2B />
      <Footer
        pressKitUrl={params.prefetchedBrandIdentity.pressKitUrl}
        currentLocale={params.locale}
        availableLocales={Object.values(AVAILABLE_LOCALES)}
        onLocaleChange={onLocalChange}
      />
    </Layout>
  );
};

export default HomePage;
