import React, { ButtonHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import {
  defaultButtonStyle,
  styledKind,
  styledSize,
} from "~/guidelines/Button/StyledComponents";
import { KeyboardArrowDown } from "~/guidelines/Icon";

/**
 * We cannot reuse our guidelines/Button component as trigger because of incompatibility of Props with the trigger props from Menu
 * I think we should think again this system
 */
const StyledButton = styled.button`
  ${defaultButtonStyle(false)}
  ${styledSize("normal")}
  ${styledKind("tertiary", "dark_background")}
  /** Dirty hack to ensure consistency on homepage */
  color: white;
  border-color: white;
`;

export type TextualTriggerProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
};

const ButtonTrigger = React.forwardRef<HTMLButtonElement, TextualTriggerProps>(
  function TextualTriggerForwardRef(
    { children, ...ariaProps }: TextualTriggerProps,
    ref,
  ) {
    return (
      <StyledButton {...ariaProps} ref={ref} className="ButtonTrigger">
        {children} <KeyboardArrowDown aria-hidden="true" />
      </StyledButton>
    );
  },
);

export default ButtonTrigger;
