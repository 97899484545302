import { Trans, t } from "@lingui/macro";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "~/guidelines/Button";
import { Flex, Stack } from "~/guidelines/Flex";
import SectionTitle from "~/guidelines/SectionTitle";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1 } from "~/guidelines/Typography";
import img1 from "../_assets/privatization/privat-1.jpeg";
import img2 from "../_assets/privatization/privat-2.jpeg";
import img3 from "../_assets/privatization/privat-3.jpeg";
import img4 from "../_assets/privatization/privat-4.jpeg";
import img5 from "../_assets/privatization/privat-5.jpeg";
import img6 from "../_assets/privatization/privat-6.jpg";
import img7 from "../_assets/privatization/privat-7.jpg";

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.background.lightThird};
  padding: ${({ theme }) => theme.spacing(7, 0, 0, 5)};
  ${mediaQueries.md()} {
    padding-top: 135px;
  }
`;
const Container = styled(Flex)`
  flex-direction: row-reverse;
  max-width: 1440px;
  margin: 0 auto;
  gap: ${({ theme }) => theme.spacing(7)};
  padding-top: 40px;

  ${mediaQueries.md()} {
    gap: 154px;
    flex-direction: row;
    padding-top: 0;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  width: 90%;
  gap: 0;
  height: 300px;
  align-self: end;
  ${mediaQueries.md()} {
    height: 90vh;
    width: 50%;
  }
`;
const Area = styled.div<{ cols: number; rows: number; x: number; y: number }>`
  grid-column: ${(props) => props.x} / span ${(props) => props.cols};
  grid-row: ${(props) => props.y} / span ${(props) => props.rows};
  background: ${({ theme }) => theme.color.background.lightSecondary};
  border: 3px solid #000;
  ${mediaQueries.md()} {
    border-width: 10px;
  }
  overflow: hidden;
`;
const Content = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(7)};
  padding-bottom: ${({ theme }) => theme.spacing(7)};
  ${mediaQueries.md()} {
    padding-top: 135px;
    padding-bottom: 0;
  }
`;
const Text = styled.p`
  ${body1}
  margin: 0;
  max-width: 400px;
`;
const MobileSectionTitle = styled(SectionTitle)`
  display: flex;
  ${mediaQueries.md()} {
    display: none;
  }
`;
const DesktopSectionTitle = styled(SectionTitle)`
  display: none;
  ${mediaQueries.md()} {
    display: flex;
  }
`;
const AnimatedImage = styled(motion(Image))`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const getRandomImages = (exclude: number[], count: number) => {
  const availableImages = Array.from({ length: 7 }, (_, i) => i).filter(
    (index) => !exclude.includes(index),
  );
  const shuffled = availableImages.sort(() => Math.random() - 0.5);
  return shuffled.slice(0, count);
};

const Privatization = () => {
  const [visibleImages, setVisibleImages] = useState<number[]>([]);
  const previousImages = useRef<number[]>([0, 2]);

  useEffect(() => {
    const updateVisibleImages = () => {
      const newImages = getRandomImages(previousImages.current, 2);
      previousImages.current = newImages;
      setVisibleImages(newImages);
    };

    const interval = setInterval(updateVisibleImages, 2000);
    return () => clearInterval(interval);
  }, []);

  const animationConfig = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.6 },
  };
  return (
    <Wrapper>
      <MobileSectionTitle
        firstPart={t`Privatization`}
        secondPart={t`An entire hotel just for you`}
      />
      <Container>
        <Grid>
          <Area cols={2} rows={1} x={1} y={1} style={{ borderBottom: "none" }}>
            <AnimatePresence mode="wait">
              {visibleImages.includes(5) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img6}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
          <Area cols={2} rows={2} x={1} y={2} style={{ borderBottom: "none" }}>
            <AnimatePresence mode="wait">
              {visibleImages.includes(0) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img1}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
          <Area
            cols={1}
            rows={2}
            x={3}
            y={2}
            style={{ borderBottom: "none", borderLeft: "none" }}
          >
            <AnimatePresence mode="wait">
              {visibleImages.includes(1) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img3}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
          <Area cols={2} rows={2} x={1} y={4} style={{ borderBottom: "none" }}>
            <AnimatePresence mode="wait">
              {visibleImages.includes(2) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img2}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
          <Area
            cols={1}
            rows={2}
            x={3}
            y={4}
            style={{ borderBottom: "none", borderLeft: "none" }}
          >
            <AnimatePresence mode="wait">
              {visibleImages.includes(3) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img4}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
          <Area cols={1} rows={2} x={1} y={6}>
            <AnimatePresence mode="wait">
              {visibleImages.includes(4) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img5}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
          <Area cols={2} rows={2} x={2} y={6} style={{ borderLeft: "none" }}>
            <AnimatePresence mode="wait">
              {visibleImages.includes(6) && (
                <AnimatedImage
                  {...animationConfig}
                  src={img7}
                  width={400}
                  height={400}
                  alt=""
                />
              )}
            </AnimatePresence>
          </Area>
        </Grid>
        <Content>
          <DesktopSectionTitle
            firstPart={t`Privatization`}
            secondPart={t`An entire hotel just for you`}
          />
          <Text>
            <Trans>
              With friends, family, or for a seminar, privatize an Encore Mieux
              hotel to enjoy a special moment with all the service and comfort
              of a hotel.
            </Trans>
          </Text>
          <Button
            kind="primary"
            size="large"
            href="https://encore-mieux.typeform.com/to/eBRIsekN"
          >
            <Trans>Book</Trans>
          </Button>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Privatization;
