import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const Calendar = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.228 3H20.228C21.328 3 22.228 3.9 22.228 5V21C22.228 22.1 21.328 23 20.228 23H4.22803C3.12803 23 2.22803 22.1 2.22803 21V5C2.22803 3.9 3.12803 3 4.22803 3H5.22803V2C5.22803 1.45 5.67803 1 6.22803 1C6.77803 1 7.22803 1.45 7.22803 2V3H17.228V2C17.228 1.45 17.678 1 18.228 1C18.778 1 19.228 1.45 19.228 2V3ZM5.22803 21H19.228C19.778 21 20.228 20.55 20.228 20V8H4.22803V20C4.22803 20.55 4.67803 21 5.22803 21Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default Calendar;
