import { t } from "@lingui/macro";
import Image from "next/image";
import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import Carousel from "~/components/Carousel/Carousel";
import CapsuleBox from "~/components/Destination/Capsule";
import { TrackedButton } from "~/components/Tracking/GoogleTagManager";
import { offersRoute } from "~/core/routes";
import { Stack } from "~/guidelines/Flex";
import SectionTitle from "~/guidelines/SectionTitle";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { heading5 } from "~/guidelines/Typography";

const Container = styled.div`
  width: 100%;
  ${mediaQueries.md()} {
    text-align: center;
  }
`;

const StyledSectionTitle: typeof SectionTitle = styled(SectionTitle)`
  && {
    padding: ${({ theme }) => theme.spacing(7, 5)};

    ${mediaQueries.md()} {
      padding: 219px 0 60px;
    }
  }
`;

const DestinationCard = styled.div`
  display: inline-flex;
  padding: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  background: ${({ theme }) => theme.color.background.lightFirst};
  box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.06);
  & img {
    width: 245px;
    object-fit: cover;
    ${mediaQueries.md()} {
      width: 332px;
    }
  }
`;

const Content = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(5, 6)};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.color.background.lightSecondary};
  width: 100%;
`;

const Title = styled.h3`
  ${heading5}
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.color.text.lightFirst};
  word-break: break-word;
  overflow-wrap: break-word;
`;

const Wrapper = styled.div`
  width: 100%;
  swiper-slide {
    max-width: 259px;

    ${mediaQueries.md()} {
      max-width: 346px;
    }
  }
`;

const DestinationsCarousel = ({
  destinations,
}: {
  destinations: DestinationPage[];
}) => {
  const allDestinations = [...destinations];

  return (
    <>
      <Container>
        <StyledSectionTitle firstPart={t`Our`} secondPart={t`Destinations`} />
      </Container>
      <Wrapper>
        <Carousel items={allDestinations}>
          {(item) => (
            <DestinationCard>
              <Image
                src={item.photo.url}
                alt={item.destination.name}
                width={332}
                height={322}
                sizes="332px, (max-width: 768px) 245px "
              />
              <Content>
                {item.capsule && <CapsuleBox capsule={item.capsule} />}
                <Title>{item.destination.name}</Title>
                <TrackedButton
                  size="large"
                  kind="primary"
                  href={
                    item.seoSlug !== "bourges"
                      ? offersRoute(item.seoSlug, undefined, undefined)
                      : undefined
                  }
                  isDisabled={item.seoSlug === "bourges"}
                  ctaName="book"
                  ctaLocation="destination_carousel"
                >
                  {item.seoSlug === "bourges" ? t`We're coming` : t`Book`}
                </TrackedButton>
              </Content>
            </DestinationCard>
          )}
        </Carousel>
      </Wrapper>
    </>
  );
};

export default DestinationsCarousel;
