import styled from "styled-components";
import { Button } from "~/guidelines/Button";
import { Stack } from "~/guidelines/Flex";
import { body2, heading5 } from "~/guidelines/Typography";

const Container = styled(Stack)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing(6)};
  align-self: stretch;
`;

const Header = styled(Stack)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  align-self: stretch;
`;

const Title = styled.h3`
  ${heading5}
  margin: 0;
`;

const Subtitle = styled.p`
  margin: 0;
  ${body2}
`;

export default function CtaLight({
  title,
  subtitle,
  buttonLabel,
  buttonLink,
}: {
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonLink: string;
}) {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <Button size="large" kind="tertiary" href={buttonLink}>
        {buttonLabel}
      </Button>
    </Container>
  );
}
