import { Trans, t } from "@lingui/macro";
import { motion, useScroll, useTransform } from "framer-motion";
import Image, { StaticImageData } from "next/image";
import React, { useRef } from "react";
import styled from "styled-components";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1, heading2 } from "~/guidelines/Typography";

/**
 * Inspiration from https://dev.to/madsstoumann/wheel-of-fortune-with-css-p-pi-1ne9
 */
const Container = styled.div`
  max-width: 100%;
  display: block;
  height: 200vh;
  position: relative;
`;

const MotionUl = styled(motion.ul)`
  all: unset;
  display: grid;
  inset: 0;
  place-content: center start;
  rotate: 90deg;

  li {
    align-content: center;
    display: grid;
    grid-area: 1 / -1;
    rotate: calc(360deg / var(--_items) * calc(var(--_idx) - 1));
    transform-origin: center right;
    user-select: none;
    width: 50cqi;

    &:nth-of-type(1) {
      --_idx: 1;
    }
    &:nth-of-type(2) {
      --_idx: 2;
    }
    &:nth-of-type(3) {
      --_idx: 3;
    }
    &:nth-of-type(4) {
      --_idx: 4;
    }
    &:nth-of-type(5) {
      --_idx: 5;
    }
    &:nth-of-type(6) {
      --_idx: 6;
    }

    img {
      rotate: -90deg;
    }
  }
`;

const StyledLi = styled.li`
  img {
    box-shadow:
      0px 2.25px 3.75px 0px rgba(104, 85, 39, 0.05),
      0px 6.75px 12px 0px rgba(104, 85, 39, 0.07);
    width: 190px;
    height: auto;
  }
`;

const WheelOfFortune = styled(motion.fieldset)`
  --_items: 24;
  all: unset;
  aspect-ratio: 1 / 1;
  container-type: inline-size;
  direction: ltr;
  display: grid;
  position: relative;
  width: 4000px;
  left: 50%;
  margin-left: -2000px;

  & > * {
    position: absolute;
  }
`;

const StickyDiv = styled.div`
  padding-top: 15vh;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
      180deg,
      rgba(243, 237, 223, 0) 48.98%,
      #f3eddf 100%
    ),
    #fff;
`;

const ContentContainer = styled(motion.div)`
  text-align: center;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20vh;
  width: 100%;

  ${mediaQueries.lg()} {
    width: 792px;
    bottom: 10vh;
  }
`;

const Title = styled.h2`
  ${heading2}
  color: ${({ theme }) => theme.color.text.lightTertiary};
`;
const Description = styled.p`
  ${body1}
  color: ${({ theme }) => theme.color.text.lightFirst};
  display: none;
  ${mediaQueries.lg()} {
    width: 792px;
    display: block;
  }
`;

const CircularCarousel = ({ screens = [] }: { screens: StaticImageData[] }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const rotate = useTransform(scrollYProgress, [0, 1.1], ["0deg", "-120deg"]);
  const opacity = useTransform(scrollYProgress, [0.8, 0.9, 1], [1, 0.9, 0]);

  return (
    <Container ref={targetRef}>
      <StickyDiv>
        <ContentContainer
          style={{ opacity }}
          transition={{ duration: 2000, ease: "easeInOut" }}
        >
          <Title>
            <Trans>_digital_experience_</Trans>
          </Title>
          <Description>
            {t`ENCORE MIEUX reinvents hospitality by blending the charm of the past with the intelligence of the present. The result? Rooms with autonomous access and all the modern comforts in buildings with character.`}
          </Description>
        </ContentContainer>
        <WheelOfFortune
          style={{ opacity }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <MotionUl style={{ rotate }}>
            {screens.map((image, index) => (
              <StyledLi key={index}>
                <Image
                  src={image.src}
                  alt={`Item ${index + 1}`}
                  width={400}
                  height={700}
                  style={{ objectFit: "cover", borderRadius: "16px" }}
                />
              </StyledLi>
            ))}
          </MotionUl>
        </WheelOfFortune>
      </StickyDiv>
    </Container>
  );
};

export default CircularCarousel;
