import { Trans } from "@lingui/macro";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import LocalePicker from "~/components/LocalePicker";
import ButtonTrigger from "~/components/Trigger/ButtonTrigger";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { gogailleJourneyRoute } from "~/core/routes";
import { Button } from "~/guidelines/Button";
import Instagram from "~/guidelines/Icon/Social/Instagram";
import EncoreMieuxSigle, { Svg } from "~/guidelines/Logo/EncoreMieuxSigle";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";

const StyledHeader = styled.header`
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 1000;
  color: ${({ theme }) => theme.color.text.lightFirst};
  background: linear-gradient(rgb(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0) 98%);
  &,
  & svg {
    transition:
      background-color 250ms ease,
      color 200ms ease,
      box-shadow 200ms ease;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(4)};

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(6)};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const LogoBlock = styled.div`
  & ${Svg} {
      height: 57.6144px;
      width: 41.277px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  transition: gap 250ms ease;
  align-items: center;
`;

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > ${Content} {
    gap: 16px;
  }
`;

const StyledLink = styled(Link)`
  line-height: 85%;
  & > svg {
    height: 36px;
    width: auto;
    color: white;
  }
`;

const SimpleHeader = ({
  logoHref,
  currentLocale,
  availableLocales,
  onLocaleChange,
}: {
  logoHref: string;
  currentLocale: AVAILABLE_LOCALES;
  availableLocales: AVAILABLE_LOCALES[];
  onLocaleChange: (newLocale: AVAILABLE_LOCALES) => void;
}) => {
  return (
    <StyledHeader>
      <HeaderContainer>
        <LogoContainer>
          <LogoBlock>
            <Link href={logoHref} passHref aria-label="EncoreMieux">
              <EncoreMieuxSigle color={theme.color.background.lightFirst} />
            </Link>
          </LogoBlock>
        </LogoContainer>

        <ActionBlock>
          <Content>
            <Button
              kind="secondary"
              size="normal"
              variant={"dark_background"}
              href={gogailleJourneyRoute()}
              prefetch={false}
            >
              <Trans>My stays</Trans>
            </Button>
            <LocalePicker
              currentLocale={currentLocale}
              availableLocales={availableLocales}
              onLocaleChange={onLocaleChange}
              picker={(triggerButtonProps, triggerButtonRef) => (
                <ButtonTrigger {...triggerButtonProps} ref={triggerButtonRef}>
                  {currentLocale}
                </ButtonTrigger>
              )}
            />
            <StyledLink
              href="https://www.instagram.com/encoremieuxici/"
              aria-label="Instagram"
              rel="noopener"
              target="_blank"
              className="header-link"
            >
              <Instagram />
            </StyledLink>
          </Content>
        </ActionBlock>
      </HeaderContainer>
    </StyledHeader>
  );
};

export default SimpleHeader;
