import { Trans, t } from "@lingui/macro";
import styled from "styled-components";
import { TrackedButton } from "~/components/Tracking/GoogleTagManager";
import { Stack } from "~/guidelines/Flex";
import { body1, heading2, heading5 } from "~/guidelines/Typography";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.background.darkSecond};
  width: 100%;
  padding: ${({ theme }) => theme.spacing(13, 3)};
`;

const Container = styled(Stack)`
  max-width: 768px;
  margin: 0 auto;
  gap: ${({ theme }) => theme.spacing(8)};
  text-align: center;
  align-items: center;
`;

const Title = styled.h3`
  ${heading5}
  margin: 0;
  color: ${({ theme }) => theme.color.text.darkFirst};
`;
const Subtitle = styled.h4`
  ${heading2}
  margin: 0;
  color: ${({ theme }) => theme.color.text.darkFirst};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 195px;
  width: 290px;
  gap: 0;
`;
const Area = styled.div<{ cols: number; rows: number; x: number; y: number }>`
  grid-column: ${(props) => props.x} / span ${(props) => props.cols};
  grid-row: ${(props) => props.y} / span ${(props) => props.rows};
  border: 3px solid ${({ theme }) => theme.color.text.darkFirst};
  color: ${({ theme }) => theme.color.text.darkFirst};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "OrganettoVariable", sans-serif;
  font-style: normal;
  font-size: 6px;
  line-height: 16.2px;
  font-weight: 57;
  font-stretch: 80%;
`;
const Text = styled.p`
  ${body1}
  color: ${({ theme }) => theme.color.text.darkThird};
  margin: 0;
`;

const B2B = () => {
  return (
    <Wrapper>
      <Container>
        <Title>{t`Hospitality professionals`}</Title>
        <Subtitle>
          <Trans>Do you have a project?</Trans>
        </Subtitle>
        <Grid>
          <Area cols={2} rows={1} x={5} y={1} style={{ borderBottom: "none" }}>
            C
          </Area>
          <Area cols={1} rows={2} x={1} y={2}>
            A
          </Area>
          <Area cols={2} rows={2} x={2} y={2} style={{ borderLeft: "none" }}>
            B
          </Area>
          <Area
            cols={1}
            rows={2}
            x={4}
            y={2}
            style={{ borderLeft: "none", borderRight: "none" }}
          >
            A
          </Area>
          <Area cols={2} rows={2} x={5} y={2}>
            B
          </Area>
          <Area cols={2} rows={1} x={2} y={4} style={{ borderTop: "none" }}>
            C
          </Area>
        </Grid>
        <Text>
          <Trans>
            Whether you are professional investors or individuals, Encore Mieux
            breaks the codes of hospitality with autonomous and unique
            accommodations
          </Trans>
        </Text>
        <TrackedButton
          variant="dark_background"
          size="large"
          href="https://encore-mieux.typeform.com/to/p5ssSaDE"
          rel="noopener"
          target="_blank"
          ctaName="Contact Us"
          ctaLocation="homepage_section"
          gaEventName="form_submit_projet_contact"
        >
          <Trans>Contact us</Trans>
        </TrackedButton>
      </Container>
    </Wrapper>
  );
};

export default B2B;
