import { Trans, t } from "@lingui/macro";
import { useRouter } from "next/compat/router";
import { Key, useCallback, useEffect, useState } from "react";
import { OverlayTriggerState } from "react-stately";
import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import { Guests } from "~/applications/OfferSearch/Domain/Guests";
import { TravelPurpose } from "~/applications/OfferSearch/Domain/TravelPurpose";
import DatesMenuWithTextualTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/DatesMenuWithTextualTrigger";
import GuestsMenuWithTextualTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/GuestsMenuWithTextualTrigger";
import CapsuleBox from "~/components/Destination/Capsule";
import { TrackedButton } from "~/components/Tracking/GoogleTagManager";
import StayRequest from "~/core/StayRequest";
import { offersRoute } from "~/core/routes";
import { Flex } from "~/guidelines/Flex";
import { Item, Select } from "~/guidelines/Form/Select/Select";
import { Calendar, Person, PinPoint, Search } from "~/guidelines/Icon";
import { SmallCenteredPulseLoader } from "~/guidelines/Loaders";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const Box = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(5, 6)};
  align-items: center;
  justify-content: center;
  border-radius: 16px; // specific ?
  background: ${({ theme }) => theme.color.background.lightFirst};
  box-shadow: 0px 24px 70px 0px rgba(0, 0, 0, 0.25); // specific ?
  width: 100%;
  margin: 0 auto;
  > #desktop-button {
    display: none;
  }
  ${mediaQueries.md()} {
    border-radius: 65px; // specific ?
    width: 1280px;

    > #desktop-button {
      display: flex;
    }
    > #mobile-button {
      display: none;
    }
  }
`;

const FormContainer = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  flex: 1 0 0;
  display: none;
  ${mediaQueries.md()} {
    display: flex;
  }
`;

const SearchItem = styled(Flex)`
  padding: ${({ theme }) => theme.spacing(4, 6)};
  gap: ${({ theme }) => theme.spacing(5)};
  align-items: center;
  flex: 1 0 0;

  .ButtonTrigger {
    color: ${({ theme }) => theme.color.text.lightFirst};
  }
`;
const SearchItemWithBg = styled(SearchItem)`
  background: ${({ theme }) => theme.color.background.lightSecond};
  border-radius: 100px;
`;

const Separator = styled.div`
  width: 0;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.color.background.lightThird};
`;

const DestinationLabel = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

const CustomSelectWrapper = styled.div`
  width: 100%;

  * > button {
    border: none;
    background: transparent;
    height: 24px;
  }
  .floating-container {
    padding: 0;

    & > span {
      transform: none;
    }
  }
`;

export default function SearchBox({
  destinations,
  onSearch,
}: {
  destinations: DestinationPage[];
  onSearch: () => void;
}) {
  const router = useRouter();
  const [destination, setDestination] = useState<string | undefined>(undefined);
  const stayRequest = StayRequest.forTonight(new Date(), 2, 0, 0);
  const [guests, setGuests] = useState<Guests | undefined>(stayRequest?.guests);
  const [travelPurpose, setTravelPurpose] = useState<TravelPurpose | undefined>(
    stayRequest?.travelPurpose,
  );
  const [dates, setDates] = useState<
    | {
        checkIn: Date;
        checkOut: Date;
      }
    | undefined
  >(() =>
    stayRequest
      ? {
          checkIn: stayRequest.checkIn,
          checkOut: stayRequest.checkOut,
        }
      : undefined,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleStop = () => setLoading(false);

    if (!router) {
      return;
    }

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  const onStayRequestChange = useCallback(
    (newStayRequest: StayRequest) => {
      if (destination && router) {
        router.push(
          offersRoute(destination, newStayRequest, undefined),
          undefined,
          { shallow: true },
        );
      }
    },
    [router, destination],
  );

  const handleSubmitGuestsMenu = useCallback(
    (
      newGuests: {
        nbAdults: number;
        nbChildren: number;
        nbBabies: number;
      },
      newTravelPurpose: TravelPurpose,
      menuState: OverlayTriggerState,
    ) => {
      setGuests({
        nbAdult: newGuests.nbAdults,
        nbChildren: newGuests.nbChildren,
        nbBabies: newGuests.nbBabies,
      });
      setTravelPurpose(newTravelPurpose);
      menuState.close();
    },
    [],
  );

  const handleSubmitDatesMenu = useCallback(
    (
      newDates: { checkIn: Date; checkOut: Date },
      menuState: OverlayTriggerState,
    ) => {
      setDates(newDates);
      menuState.close();
    },
    [],
  );

  const isReadyToSearch = destination && guests && dates;

  if (!router) {
    return (
      <Box>
        <SmallCenteredPulseLoader />
      </Box>
    );
  }
  return (
    <Box>
      <FormContainer>
        <SearchItemWithBg>
          <PinPoint />
          <CustomSelectWrapper>
            <Select
              useFloatingLabel={false}
              label={t`Destination`}
              selectedKey={destination}
              onSelectionChange={(newValue: Key) =>
                setDestination(newValue as string)
              }
              isRequired={true}
            >
              {destinations.map((oneDestination) => (
                <Item
                  key={oneDestination.seoSlug}
                  textValue={oneDestination.destination.name}
                >
                  <DestinationLabel>
                    {oneDestination.capsule && (
                      <CapsuleBox capsule={oneDestination.capsule} />
                    )}
                    {oneDestination.destination.name}
                  </DestinationLabel>
                </Item>
              ))}
            </Select>
          </CustomSelectWrapper>
        </SearchItemWithBg>
        <Separator />
        <SearchItem>
          <Person />

          <GuestsMenuWithTextualTrigger
            defaultGuests={
              guests // Because we fucked up the StayRequest class by forgetting to add a s to nbAdult
                ? {
                    nbAdults: guests.nbAdult,
                    nbChildren: guests.nbChildren,
                    nbBabies: guests.nbBabies,
                  }
                : undefined
            }
            defaultTravelPurpose={travelPurpose}
            onSubmit={handleSubmitGuestsMenu}
            offerMaxCapacity={undefined}
          />
        </SearchItem>
        <Separator />

        <SearchItem>
          <Calendar />

          <DatesMenuWithTextualTrigger
            firstAvailableDate={null}
            dates={dates}
            onSubmit={handleSubmitDatesMenu}
          />
        </SearchItem>
      </FormContainer>
      <TrackedButton
        id="desktop-button"
        kind="primary"
        variant="light_background"
        size="large"
        onPress={() =>
          isReadyToSearch &&
          onStayRequestChange(
            new StayRequest(
              dates.checkIn,
              dates.checkOut,
              guests.nbAdult,
              guests.nbChildren,
              guests.nbBabies,
              travelPurpose,
            ),
          )
        }
        isDisabled={!isReadyToSearch}
        isLoading={loading}
        ctaName="Search"
        ctaLocation="desktop hero"
      >
        <Trans>Search</Trans>
      </TrackedButton>
      <TrackedButton
        id="mobile-button"
        fluid
        kind="quaternary"
        size="large"
        leadingIcon={<Search />}
        onPress={onSearch}
        ctaName="Find a room"
        ctaLocation="mobile hero"
      >
        <Trans>Find a room</Trans>
      </TrackedButton>
    </Box>
  );
}
