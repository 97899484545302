import { t } from "@lingui/macro";
import React, {
  ButtonHTMLAttributes,
  MutableRefObject,
  ReactNode,
} from "react";
import styled from "styled-components";
import Menu, {
  useMenuState,
} from "~/applications/OfferSearch/Ui/StayRequestForm/Menu";
import Selector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/Selector";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { body1, heading6 } from "~/guidelines/Typography";

type Variant = "light" | "dark";

const StyledList = styled.ul<{ variant: Variant }>`
  padding: 0;
  margin: 0;
  unset: all;
  color: ${({ theme, variant }) =>
    variant == "dark"
      ? theme.color.text.darkFirst
      : theme.color.text.lightFirst};
`;

const StyledItem = styled.li<{ isSelected: boolean }>`
  unset: all;
  list-style-type: none;
  margin: 0;
  min-height: 42px;
  padding: ${({ theme }) => theme.spacing(3, 4)};
  outline: none;
  cursor: pointer;

  ${({ isSelected }) => (isSelected ? heading6 : body1)};

  color: ${({ theme }) => theme.color.text.lightFirst};

  :hover {
    background: ${({ theme }) => theme.color.background.lightSecond};
  }
`;

export const LocalePicker = ({
  currentLocale,
  availableLocales,
  onLocaleChange,
  variant = "light",
  picker,
}: {
  currentLocale: AVAILABLE_LOCALES;
  availableLocales: AVAILABLE_LOCALES[];
  onLocaleChange: (newLocale: AVAILABLE_LOCALES) => void;
  variant?: "light" | "dark";
  picker: (
    triggerButtonProps: ButtonHTMLAttributes<HTMLButtonElement>,
    triggerButtonRef: MutableRefObject<HTMLButtonElement>,
  ) => ReactNode;
}) => {
  const state = useMenuState();

  return (
    <Menu
      state={state}
      title={t`Change language`}
      triggerButtonRender={picker}
      footer={undefined}
      variant={variant}
    >
      <Selector.Layout>
        <StyledList variant="light">
          {availableLocales.map((oneAvailableLocale) => (
            <StyledItem
              key={oneAvailableLocale}
              onClick={() => onLocaleChange(oneAvailableLocale)}
              isSelected={oneAvailableLocale === currentLocale}
            >
              {oneAvailableLocale}
            </StyledItem>
          ))}
        </StyledList>
      </Selector.Layout>
    </Menu>
  );
};

export default LocalePicker;
