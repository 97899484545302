import { Trans, t } from "@lingui/macro";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Swiper from "swiper";
import { SwiperContainer, register } from "swiper/element";
import Navigation from "~/components/Carousel/Navigation";
import { Stack } from "~/guidelines/Flex";
import EncoreMieux from "~/guidelines/Logo/EncoreMieux";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1, heading4, heading5 } from "~/guidelines/Typography";
import accessautonome from "../_assets/promesses/accessautonome-4.jpg";
import batiment from "../_assets/promesses/batiment-historique.jpeg";
import breakfast from "../_assets/promesses/breakfast.jpeg";
import coeurdeville from "../_assets/promesses/coeur-de-ville.jpeg";
import localhumain from "../_assets/promesses/local-et-humain-2.jpg";
import nuittranquille from "../_assets/promesses/nuit-tranquille.jpeg";

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3)};
  ${mediaQueries.lg()} {
    padding: ${({ theme }) => theme.spacing(7)};
  }
  width: 100%;
  height: 100vh;
`;
const SliderContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;

  > swiper-container {
    height: 50%;
    ${mediaQueries.lg()} {
      height: 100%;
    }
  }
  border-radius: 32px; // specific ?
  border: 4px solid black;
  ${mediaQueries.lg()} {
    border: none;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 32px;
  left: 6vw;
  z-index: 10;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 25%;

  & svg {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
    max-width: 100%;
    height: auto;
  }

  ${mediaQueries.lg()} {
    top: 13%;
    gap: 16px;
  }
`;

const MainTitle = styled.h3`
  ${heading4}
  margin: 0;
  color: #ffffff;
  padding-left: 6px;
  font-size: 16px;
  line-height: 21px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
  ${mediaQueries.lg()} {
    padding-left: 28px;
  }
`;

const Slide = styled(Stack)`
  position: relative;
  width: 100%;
  max-height: 900px;
  height: 100%;
  align-items: start;
  overflow: hidden;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mediaQueries.lg()} {
    border: 4px solid black;
    border-radius: 32px; // specific ?
  }
`;

const Description = styled(motion.p)`
  ${body1}
  margin: 0;
  padding: ${({ theme }) => theme.spacing(6)};
  text-transform: none;
  white-space: normal;
  text-align: left;
  height: 50%;
  background: ${({ theme }) => theme.color.background.lightFirst};

  ${mediaQueries.lg()} {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 350px;
    background: ${({ theme }) => theme.color.background.lightSecond};
    height: auto;
    border-radius: ${({ theme }) => theme.borderRadius.xlarge};
  }
`;

const DesktopNavigation = styled.div`
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: #ffffff;
  padding: 2px;
  border-radius: 28px;
  display: none;
  ${mediaQueries.lg()} {
    display: flex;
  }
`;

const MobileNavigation = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(6, 7)};
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: center;
  background: ${({ theme }) => theme.color.background.lightFirst};
  height: 50%;
  ${mediaQueries.lg()} {
    display: none;
  }
`;

const StyledNavigation = styled(Navigation)`
  padding-top: 0;
`;

const NavItem = styled.button<{ active: boolean }>`
  ${body1}
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 28px;
  background: ${(props) => (props.active ? "#000" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  border: 1px solid transparent;
  text-transform: uppercase;
  position: relative;

  &:hover {
    border-color: black;
  }

  ${mediaQueries.lg()} {
    &:last-child {
      ${Description} {
        right: 0;
        left: auto;
      }
    }
  }
`;

const NavigationTitle = styled.h5`
  ${heading5}
  margin: 0;
`;

const NavigationContent = styled(motion.p)`
  ${body1}
  margin: 0;
  color: ${({ theme }) => theme.color.text.lightThird};
`;

const Promises = () => {
  const slides = [
    {
      image: batiment,
      title: t`Character Buildings`,
      description: t`Each of our destinations is selected for its unique history, where the past and future merge harmoniously. Our locations, full of uniqueness and charm, are designed to make your stay unforgettable.`,
    },
    {
      image: coeurdeville,
      title: t`City Center`,
      description: t`Ideally located in the city center, our establishments allow you to explore historic centers on foot, offering a complete immersion in iconic locations.`,
    },
    {
      image: nuittranquille,
      title: t`Peaceful Nights`,
      description: t`Our bed frames, mattresses, and pillows are of exceptional quality to ensure you have soft, restorative nights. Perfect soundproofing in our rooms and a fully air-conditioned property allow you to sleep soundly.`,
    },
    {
      image: breakfast,
      title: t`Breakfast`,
      description: t`Hot drinks, juices, pastries, yogurts, bread, jams, butter, fresh fruits… Create your breakfast and take the time to start your day right in our lounge.`,
    },
    {
      image: localhumain,
      title: t`Human & Local`,
      description: t`In each of our destinations, our local managers and responsive customer service support you throughout your stay.`,
    },
    {
      image: accessautonome,
      title: t`Digital Access`,
      description: t`From check-in to check-out, our solution and customer service support you at every step, so you can take control of your time.`,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperContainer>(null);
  const [showDescription, setShowDescription] = useState(true);

  useEffect(() => {
    register();

    if (swiperRef.current === null) {
      return;
    }

    swiperRef.current.initialize();
    swiperRef.current.swiper.on("activeIndexChange", (swiper: Swiper) => {
      setActiveIndex(swiper.activeIndex);
    });
  }, [swiperRef]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.on("slideChangeTransitionEnd", () => {
        setShowDescription(true);
      });
      swiperRef.current.swiper.on("slideChangeTransitionStart", () => {
        setShowDescription(false);
      });
    }
  }, []);

  const handleNavClick = (index: number) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const currentSlide = slides[activeIndex];

  return (
    <Wrapper>
      <SliderContainer>
        <Title>
          <MainTitle>
            <Trans>Promises</Trans>
          </MainTitle>
          <EncoreMieux />
        </Title>

        <swiper-container init={false} ref={swiperRef}>
          {slides.map((slide, index) => (
            <swiper-slide key={index}>
              <Slide>
                <SlideImage src={slide.image.src} alt={slide.title} />
              </Slide>
            </swiper-slide>
          ))}
        </swiper-container>

        <MobileNavigation>
          <StyledNavigation
            swiperRef={swiperRef}
            activeIndex={activeIndex}
            length={slides.length}
          />
          <AnimatePresence>
            <NavigationTitle>{currentSlide.title}</NavigationTitle>
            {showDescription && (
              <NavigationContent
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.2, ease: "easeIn" }}
              >
                {currentSlide.description}
              </NavigationContent>
            )}
          </AnimatePresence>
        </MobileNavigation>

        <DesktopNavigation>
          {slides.map((slide, index) => (
            <NavItem
              key={index}
              active={index === activeIndex}
              onClick={() => handleNavClick(index)}
            >
              {slide.title}
              <AnimatePresence>
                {showDescription && activeIndex === index && (
                  <Description
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2, ease: "easeIn" }}
                  >
                    {slide.description}
                  </Description>
                )}
              </AnimatePresence>
            </NavItem>
          ))}
        </DesktopNavigation>
      </SliderContainer>
    </Wrapper>
  );
};

export default Promises;
