import { t } from "@lingui/macro";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import { IconOnlyButton } from "~/guidelines/Button";
import { Flex, Stack } from "~/guidelines/Flex";
import { Add, Remove } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1, body1Bold, heading1 } from "~/guidelines/Typography";
import CtaLight from "./CtaLight";

const Container = styled(Stack)`
  max-width: 1440px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(7, 5)};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(8)};
  ${mediaQueries.md()} {
    padding-top: 160px;
    padding-bottom: 110px;
    gap: ${({ theme }) => theme.spacing(9)};
  }
`;

const Content = styled(Stack)`
  width: 100%;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.color.background.lightFirst};
  border: 4px solid ${({ theme }) => theme.color.line.lightTertiary};
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(8)};
    border-width: 10px;
  }
`;

const Title = styled.h2`
  ${heading1}
  color: ${({ theme }) => theme.color.text.lightFirst};
  width: 100%;
  margin: 0;
`;

const Heading = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing(7)};
  align-self: stretch;
`;

const Question = styled.h3`
  ${body1Bold}
  margin: 0;
  flex: 1;
  cursor: pointer;
`;

const Answer = styled(motion.p)`
  ${body1}
  margin: 0;
  overflow: hidden;
`;

const Card = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(5)};
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(7)};
  border-bottom: 1px solid ${({ theme }) => theme.color.line.lightFirst};

  &:last-child {
    border-bottom: none;
  }

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(7, 6)};
  }
`;

export default function Faq() {
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined);
  const handleToggle = (index: number) => {
    setActiveItem((prevIndex) => (prevIndex === index ? undefined : index));
  };
  const faqItems = [
    {
      question: t`How is breakfast served?`,
      answer: t`Breakfast is available from 7 AM to 10 AM. It is a self-service buffet. We offer hot drinks and fruit juices, milk, and cereals. This is complemented by pastries, bread, butter, and jam. You will also find yogurts, cheese, cold cuts, eggs, and fruit."`,
    },
    {
      question: t`What does online check-in consist of?`,
      answer: t`Online check-in involves confirming your personal information such as your first name, last name, email, and phone number, and validating your ID (identity card or passport). We take great care in verifying your identity to ensure the safety of all our guests.`,
    },
    {
      question: t`What happens if my battery is dead when I arrive? `,
      answer: t`Don't worry, we have intercoms located outside and inside our buildings. They are always connected to our 24/7 online reception. We are therefore able to remotely open all the building's doors. Additionally, we always have an induction charger available in our living-room.`,
    },
    {
      question: t`What happens if I forget my phone in my room? `,
      answer: t`Don’t worry, simply go to the intercom located at the building entrance and contact our customer service. We will remotely open the door to your room.`,
    },
    {
      question: t`What happens if I don’t have phone signal?`,
      answer: t`We have a great free Wi-Fi network, just connect to: ENCOREMIEUX-PUBLIC`,
    },
    {
      question: t`I'm arriving earlier or leaving later, what can I do with my luggage? `,
      answer: t`We have self-service luggage storage available in all our Encore Mieux hotels. Also, on the day of your departure, you have access to all the common areas until 6 PM.`,
    },
  ];

  return (
    <Container>
      <Title>Foire aux questions</Title>
      <Content>
        {faqItems.map((item, index) => (
          <Card key={index}>
            <Heading>
              <Question onClick={() => handleToggle(index)}>
                {item.question}
              </Question>
              <IconOnlyButton
                size="normal"
                kind="quaternary"
                aria-label={t`Toggle answer`}
                icon={index === activeItem ? <Remove /> : <Add />}
                onPress={() => handleToggle(index)}
              />
            </Heading>
            <AnimatePresence>
              {index === activeItem && (
                <Answer
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  {item.answer}
                </Answer>
              )}
            </AnimatePresence>
          </Card>
        ))}
      </Content>
      <CtaLight
        title={t`Still have questions?`}
        subtitle={t`Our team will be delighted to help you.`}
        buttonLabel="Contact"
        buttonLink={"mailto:bonjour@encore-mieux.com"}
      />
    </Container>
  );
}
