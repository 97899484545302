import { t } from "@lingui/macro";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import EncoreMieuxLogo from "~/guidelines/Logo/EncoreMieux";
import EncoreMieuxBaseline from "~/guidelines/Logo/EncoreMieuxBaseline";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import SearchBox from "./SearchBox";

const images = [
  "/assets/hero/1.jpg",
  "/assets/hero/2.jpg",
  "/assets/hero/3.jpg",
  "/assets/hero/4.jpg",
  "/assets/hero/5.jpg",
  "/assets/hero/6.jpg",
  "/assets/hero/7.jpg",
  "/assets/hero/8.jpg",
  "/assets/hero/9.jpg",
  "/assets/hero/10.jpg",
  "/assets/hero/11.jpg",
  "/assets/hero/12.jpg",
  "/assets/hero/13.jpg",
  "/assets/hero/14.jpg",
  "/assets/hero/15.jpg",
  "/assets/hero/16.jpg",
  "/assets/hero/17.jpg",
  "/assets/hero/18.jpg",
  "/assets/hero/19.jpg",
];

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 100svh;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100svh;
  top: 0;
  left: 0;
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  flex-grow: 1;
  align-content: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    width: 100%;
    max-width: 600px;
    height: auto;
    fill: white;
    filter: drop-shadow(0px 24px 70px rgba(0, 0, 0, 0.25));
  }
`;
const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
const SearchWrapper = styled.div`
  padding: 16px;
  width: 100%;
  ${mediaQueries.md()} {
    padding: 80px 16px;
  }
`;

const Hero = ({
  destinations,
  onSearch,
}: {
  destinations: DestinationPage[];
  onSearch: () => void;
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 800);
    return () => clearInterval(interval);
  }, []);

  return (
    <HeroContainer>
      <BackgroundImageWrapper>
        {images.map((src, index) => (
          <BackgroundImage
            key={index}
            src={src}
            alt={t`Encore Mieux hotel`}
            fill
            style={{
              opacity: index === currentImageIndex ? 1 : 0,
              objectFit: "cover",
            }}
          />
        ))}
      </BackgroundImageWrapper>
      <ContentWrapper>
        <LogoWrapper>
          <EncoreMieuxLogo />
          <EncoreMieuxBaseline />
        </LogoWrapper>
        <SearchWrapper>
          <SearchBox destinations={destinations} onSearch={onSearch} />
        </SearchWrapper>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default Hero;
